/* @font-face{
  font-family: "Modernist";
  src: local("Sk-Modernist-Regular"),
    url('./font/Sk-Modernist-Regular.otf') format(truetype);
} */


@font-face{
  font-family: "Poppins-Medium";
  src: local("Poppins-Medium"),
    url('./font/Poppins-Medium.ttf') format(truetype);
}