/* #007BFF
#FF6B6B */

*{
  font-family: Poppins-Medium;
}

.navRound{
  border-radius: 30px;
}

.navItem:hover{
  color: #fff;
  border-radius: 30px;
  background-color: #FF6B6B;
}
.cont h1 span{
  color: #007BFF;
  font-size: 1.3em;
}



.hero{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  overflow-x: hidden;
}

.hero::after{
  content:'';
  position: absolute;
  /* width: 100%; */
  height:100%;
  background-color: rgba(255, 255, 255, 0.7);
  filter: blur(200px);
  z-index: -2;
}


.circle{
  width: 300px;
  height: 300px;
  border-radius: 50%;
  filter: blur(60px);
  overflow-x: hidden;
}

.circlebg{
  /* display: flex; */
  width: 90vw;
  position: absolute;
  z-index: -2;
  opacity: .7;
  width: auto;
  margin-top: -15em;
}
.circle-red{
  background: #007BFF;
  transform: translateX(60%);
  overflow: hidden;
}

.circle-blue{
  background-color: #FF6B6B;
  transform: translateX(-60%);
  overflow: hidden;
}

.circle-green{
  background-color: #007BFF;
  transform: translateX(50%);
  overflow: hidden;
}

.circle-yellow{
  background-color: #FF6B6B;
  transform: translateX(-80%);
  overflow: hidden;
}


.card1{
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  /* position: absolute; */
  background: rgba(255, 255, 255, 0.2);
  border: solid 1px rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  padding: 3rem;
  box-shadow: 0px 8px 20px rgba(0,0,0, .15);
  backdrop-filter: blur(20px);
  z-index:100;
  /* transition: width 1s,
    height 350ms; */
}


.card1 .cont{
  width: 55%;
}
.card1-img img{
  width: 38vw;
  margin-top: -3em;
  /* height: 25em; */
}

.countCard{
  display: flex;
}

.countCard .card1{
  padding: 2rem;
}

.MDCard{
  cursor: pointer;
}
.MDCard:hover{
  transition: transform .5s;
  transform: scale(1.5);
  z-index: 5;
}

.aboutCont{
  margin-top: 8em;
}

.whyImg img{
  width: 25em;
}

.whyCont{
  margin-top: 5em;
  margin-left: 3em;
}

.laptop img{
  margin-top: -4em;
  width: 50vw;
}

.createInp{
  width: 60%;
}

.create{
  width: 75%;
  box-shadow: 14px 10px 25px #888888;
}

@media only screen and (max-width: 930px){
  .circlebg{
    margin-top: -25em;
  }
  .card1 .cont{
    width: 100%;
  }
  .card1-img img{
    width: 70vw;
    margin-top: 0;
    /* height: 25em; */
  }
  .countCard{
    flex-wrap: wrap;
  }
  .about{
    flex-wrap: wrap;
  }
  .aboutCont{
    margin-top: 2em;
    margin-left: 0;
  }
  .aboutImg img{
    width: 45vh;
  }
  .whyCont2{
    flex-wrap: wrap-reverse;
  }
  .laptop img{
    margin-top: -1.5em;
    width: 80vw;
  }
  .create{
    width: auto;
  }
}

@media screen and (max-width: 700px) {
  .circle-red{
    transform: translateX(15%);
    overflow: hidden;
  }
  
  .circle-blue{
    margin-top: -5em;
    transform: translateX(-60%);
    overflow: hidden;
  }
}